import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './AdminLogin.css'
import firebase from '../firebase'

const AdminLogin = () => {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()

  const signin = async () => {
    setError('')
    if (user !== '' && password !== '') {
      try {
        const response = await firebase
          .auth()
          .signInWithEmailAndPassword(user, password)
        if (response.user) {
          history.replace('/admin')
        }
      } catch (error) {
        console.log(error.message)
        setError(error.message)
      }
    } else {
      setError('Please verify user/password')
    }
  }

  return (
    <div className="ui middle aligned center aligned grid">
      <div className="column">
        <h2 className="ui blue image header">
          <img src="/apple-icon.png" className="image" alt="" />
          <div className="content">Log-in to your account</div>
        </h2>
        {error !== '' && <div className="ui error message">{error}</div>}
        <form className="ui large form">
          <div className="ui raised segment">
            <div className="field">
              <div className="ui left icon input">
                <i className="user icon"></i>
                <input
                  type="text"
                  name="email"
                  placeholder="E-mail address"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                />
              </div>
            </div>
            <div className="field">
              <div className="ui left icon input">
                <i className="lock icon"></i>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
            </div>
            <div
              className="ui fluid large blue submit button"
              onClick={() => signin()}
            >
              Login
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminLogin
