import React, { useState, useEffect } from 'react'
import {
  Switch,
  useHistory,
  Link,
  useRouteMatch,
  Route,
} from 'react-router-dom'
import './Admin.css'

import RedirectUri from '../components/RedirectUri'
import Customer from './Customer'
import CustomerEdit from './CustomerEdit'
import firebase from '../firebase'

const Admin = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const [session, setSession] = useState({
    isLoggedIn: false,
    currentUser: null,
    errorMessage: null,
  })

  useEffect(() => {
    const handleAuth = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!user.isAnonymous) {
          setSession({
            isLoggedIn: true,
            currentUser: user,
            errorMessage: null,
          })
        } else {
          history.replace('/login')
        }
      } else {
        history.replace('/login')
      }
    })

    return () => {
      handleAuth()
    }
  }, [])

  if (!firebase.app.length) {
    return <div>Loading</div>
  }

  const handleLogout = () => {
    firebase.auth().signOut()
  }

  return (
    <>
      <div className="ui fixed blue inverted   menu">
        <div className="ui container">
          <Link to="/admin" className="header item">
            <img className="logo" src="/apple-icon.png" alt="" />
            DD Free Drop Admin
          </Link>
          <Link to="/admin" className="item">
            Home
          </Link>
          <Link to="/admin/customer" className="item">
            Customer
          </Link>
          <div className="ui simple dropdown item">
            App Config <i className="dropdown icon"></i>
            <div className="menu">
              <Link className="item" to="/admin/config">
                Redirect URI
              </Link>
            </div>
          </div>
        </div>

        <div className="ui simple dropdown item">
          {session.currentUser && session.currentUser.email}{' '}
          <i className="dropdown icon"></i>
          <div className="menu">
            <div className="item">
              <div onClick={handleLogout}>Logout</div>
            </div>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path={path}>
          <div className="ui main text container">
            <h2>Hello , {session.currentUser && session.currentUser.email}</h2>

            <button className="ui blue button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </Route>
        <Route path="/admin/config">
          <div className="ui main text container">
            <RedirectUri db={firebase.database()} />
          </div>
        </Route>
        <Route path="/admin/customer/:uuid">
          <CustomerEdit />
        </Route>
        <Route exact path="/admin/customer">
          <Customer />
        </Route>
      </Switch>
    </>
  )
}

export default Admin
