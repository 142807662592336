import React, { useState, useEffect } from 'react'
import './RedirectUri.css'

const RedirectUri = ({ db }) => {
  const [redirectUri, setRedirectUri] = useState('')

  useEffect(() => {
    const getConfig = async () => {
      let dbRef = await db.ref('Config')
      let snapshot = await dbRef.once('value')
      let config = await snapshot.val()

      setRedirectUri(config.redirectUri)
    }

    getConfig()
  }, [db])

  const updateUri = async () => {
    if (
      redirectUri !== '' &&
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
        redirectUri
      )
    ) {
      let dbRef = await db.ref('/Config')
      await dbRef.set({ redirectUri })
      alert('Update data success')
    } else {
      alert('Please check url format')
    }
  }

  return (
    <div className="container">
      <div className="ui input redirect">
        <input
          type="url"
          placeholder="Redirect url eg. https://paperform.co"
          value={redirectUri}
          onChange={(e) => setRedirectUri(e.target.value)}
        />
      </div>
      <button className="ui blue button" onClick={() => updateUri()}>
        Update
      </button>
    </div>
  )
}

export default RedirectUri
