import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import RegisterForm from '../components/RegisterForm'
import './Home.css'

import firebase from '../firebase'

const liff = window.liff

const Home = () => {
  const [uuid, setUuid] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [uri, setUri] = useState(null)

  let { form } = useParams()

  console.log('host', window.location.host)

  useEffect(() => {
    let liffId = '1654448096-lgQzPL82'

    liff.init({ liffId: liffId }, async () => {
      if (liff.isInClient()) {
        let profile = await liff.getProfile()
        setUuid(profile.userId)
      } else {
        if (liff.isLoggedIn()) {
          let profile = await liff.getProfile()
          setUuid(profile.userId)
        } else {
          liff.login()

          //liff.login({ redirectUri: 'http://localhost:3000' })
        }
      }
    })
  }, [])

  useEffect(() => {
    const getConfig = async () => {
      const ConfigRef = firebase.database().ref('Config')
      const snapshot = await ConfigRef.once('value')
      const config = await snapshot.val()

      console.log(config.redirectUri)
      setUri(config.redirectUri)

      firebase
        .database()
        .ref('userData/' + uuid)
        .once('value')
        .then((snapshot) => {
          if (snapshot.val() !== null) {
            console.log(snapshot.val())

            let userData = snapshot.val()

            if (form) {
              console.log('form is true')
              window.location.href = `https://${form}.paperform.co?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`
            } else {
              console.log('form is false')

              window.location.href = `${config.redirectUri}?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`
            }

            //window.location.href = `${config.redirectUri}?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`
            //console.log(config.redirectUri)
            return <div></div>
          }

          setIsLoading(false)
        })
    }

    const handleAuth = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user)
        if (uuid !== '') {
          getConfig()
        }
      } else {
        firebase
          .auth()
          .signInAnonymously()
          .catch((e) => console.log(e.message))
      }
    })

    return () => {
      handleAuth()
    }
  }, [uuid])

  if (isLoading) {
    return (
      <div className="ui active dimmer">
        <div className="ui small image">
          <img src="https://catering.theeateria.com/catering-logo.png" />
        </div>
        {uuid !== '' ? (
          <div className="ui text loader">
            ระบบกำลังนำท่านเข้าสู่หน้าเมนูอาหาร
          </div>
        ) : (
          <div className="ui text loader">กำลังตรวจสอบข้อมูลสมาชิก</div>
        )}
      </div>
    )
  }

  return (
    <div className="App">
      <div className="Header">
        <p className="HeaderText">The Eateria Catering</p>
      </div>
      <RegisterForm firebase={firebase} uuid={uuid} uri={uri} />
      <div className="Footer">
        <p className="FooterText">Copyright ©2021 The Eateria Catering</p>
      </div>
    </div>
  )
}

export default Home
