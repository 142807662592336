import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'

const Customer = () => {
  const [customers, setCustomers] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [lastKey, setLastKey] = useState('')
  const [prevKey, setPrevKey] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCustomer, setTotalCustomer] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  //const history = useHistory()

  useEffect(() => {
    let isSubscribed = true
    const getCustomer = async () => {
      let countDbRef = firebase.database().ref('userData')

      countDbRef.once('value', (snapshot) => {
        let x = snapshot.numChildren()
        //console.log('x', x)
        setTotalCustomer(x)

        let y = Math.ceil(x / pageSize)
        //console.log('y', y)
        setTotalPage(y)
      })

      let dbRef = firebase.database().ref('userData').limitToFirst(10)
      // dbRef.once('value').then((snapshot) => {
      //   let obj = snapshot.val()
      //   setCustomers(Object.entries(obj))
      // })

      dbRef.once('value', (snapshot) => {
        let obj = snapshot.val()
        let size = Object.keys(obj).length

        if (size === pageSize) {
          let key = Object.keys(obj)[pageSize - 1]
          setLastKey(key)
          //setPrevKey(Object.keys(obj)[0])
        }

        //console.log('lastkey', lastKey)

        setCustomers(Object.entries(obj))
      })
    }

    if (firebase.apps.length) {
      getCustomer()
    }

    return () => (isSubscribed = false)
  }, [])

  if (!firebase.apps.length) {
    return <div>Loading</div>
  }

  const handleDelete = (uuid) => {
    let shouldDelete = window.confirm('Do you really want to delete this?')

    if (shouldDelete) {
      if (uuid) {
        firebase
          .database()
          .ref(`userData/${uuid}`)
          .remove()
          .then(() => {
            alert('Remove Successful')
          })
          .catch((error) => {
            alert('Error: ' + error.message)
          })
      }
    }
  }

  const handelNext = () => {
    console.log('next')
    console.log('entering lastkey', lastKey)
    console.log('entering prevkey', prevKey)

    setCurrentPage(currentPage + 1)

    let dbRef = firebase
      .database()
      .ref('userData')
      .startAt(null, lastKey)
      .limitToFirst(pageSize + 1)
    dbRef.once('value', (snapshot) => {
      console.log(snapshot.val())
      let obj = snapshot.val()
      let size = Object.keys(obj).length

      if (size === pageSize + 1) {
        let key = Object.keys(obj)[pageSize]
        setLastKey(key)
        console.log('new lastkey', key)
      } else {
        setLastKey('')
        console.log('new lastkey to null')
      }

      console.log('New PrevKey ', Object.keys(obj)[1])
      setPrevKey(Object.keys(obj)[1])

      let cusArray = Object.entries(obj)
      cusArray.shift()

      setCustomers(cusArray)
    })
  }

  const handelPrev = () => {
    console.log('Prev')
    console.log('entering lastkey', lastKey)
    console.log('entering prevkey', prevKey)

    setCurrentPage(currentPage - 1)

    let dbRef = firebase
      .database()
      .ref('userData')
      .endAt(null, prevKey)
      .limitToLast(pageSize + 1)
    dbRef.once('value', (snapshot) => {
      console.log(snapshot.val())
      let obj = snapshot.val()
      let size = Object.keys(obj).length

      if (size === pageSize + 1) {
        let key = Object.keys(obj)[pageSize - 1]
        setLastKey(key)
      } else {
        setLastKey('')
      }

      setPrevKey(Object.keys(obj)[0])

      let cusArray = Object.entries(obj)
      cusArray.pop()

      setCustomers(cusArray)
    })
  }

  return (
    <div className="ui container" style={{ marginTop: '60px' }}>
      <table className="ui celled striped  table">
        <thead>
          <tr>
            <th>UUID</th>
            <th>Name</th>
            <th>Tel</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((i) => {
            return (
              <tr key={i[0]}>
                <td>{i[0]}</td>
                <td>{i[1].name}</td>
                <td>{i[1].tel}</td>
                <td>{i[1].email}</td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'space-between',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Link
                        to={`/admin/customer/${i[0]}`}
                        className="ui blue button"
                        style={{ width: '90%' }}
                      >
                        Edit
                      </Link>
                    </div>
                    <div style={{ flex: 1 }}>
                      <button
                        className="ui red button"
                        style={{ width: '90%' }}
                        onClick={() => handleDelete(i[0])}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
        <tfoot className="full-width">
          <tr>
            <th colSpan="5">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <div>
                  <button
                    className={
                      prevKey === '' || currentPage === 1
                        ? 'ui disabled button'
                        : 'ui button'
                    }
                    onClick={handelPrev}
                  >
                    Prev
                  </button>
                </div>
                <div
                  style={{
                    alignSelf: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <span>
                    Page : {currentPage} of {totalPage} / {totalCustomer}
                  </span>
                </div>
                <div>
                  <button
                    className={
                      lastKey === '' ? 'ui disabled button' : 'ui button'
                    }
                    onClick={handelNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default Customer
