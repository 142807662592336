import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import './RegisterForm.css'

const RegisterForm = ({ firebase, uuid, uri }) => {
  const [userData, setUserData] = useState({ name: '', tel: '', email: '' })
  const [error, setError] = useState('')

  let { form } = useParams()

  const formValidate = () => {
    if (userData.name.length < 3 || userData.tel.length < 10) {
      return false
    }

    if (userData.email !== '' && validateEmail(userData.email)) {
      return false
    }

    return true
  }

  const handleSubmit = async () => {
    setError('')
    if (!formValidate()) {
      setError('Please check input data')
    } else {
      // firebase
      //   .auth()
      //   .signInAnonymously()
      //   .catch((e) => console.log(e.message))

      const ConfigRef = firebase.database().ref('Config')
      const snapshot = await ConfigRef.once('value')
      const config = await snapshot.val()

      let dbCon = firebase.database().ref('userData/' + uuid)

      dbCon.set({
        name: userData.name,
        email: userData.email,
        tel: userData.tel,
      })

      if (form) {
        console.log('form is true')
        window.location.href = `https://${form}.paperform.co?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`
      } else {
        console.log('form is false')

        window.location.href = `${config.redirectUri}?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`
      }

      // window.location.href = `${config.redirectUri}?name=${userData.name}&tel=${userData.tel}&email=${userData.email}&uuid=${uuid}`

      resetForm()
    }
  }

  const validateEmail = (val) => {
    return !val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  }

  const resetForm = () => {
    setUserData({ name: '', tel: '', email: '' })
    setError('')
  }

  const handleContinueWithGuest = () => {
    window.location.href = uri
  }

  return (
    <div className="container">
      <div className="ui form">
        {error.length > 0 && <div className="ui red label">{error}</div>}
        <div className="required field">
          <label>ชื่อ</label>
          <input
            type="text"
            placeholder="Joe Smit."
            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
            value={userData.name}
          />
        </div>
        <div className="required field">
          <label>โทรศัพท์</label>
          <input
            type="number"
            placeholder="0819999999"
            onChange={(e) => setUserData({ ...userData, tel: e.target.value })}
            value={userData.tel}
          />
        </div>
        <div className="field">
          <label>อีเมล</label>
          <input
            type="email"
            placeholder="example@example.com"
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            value={userData.email}
          />
        </div>

        <button
          className="ui blue button"
          type="submit"
          onClick={() => handleSubmit()}
        >
          สมัครสมาชิก
        </button>

        <button
          className="ui blue basic button"
          type="submit"
          onClick={() => handleContinueWithGuest()}
        >
          สั่งซื้อโดยไม่สมัครสมาชิก
        </button>
      </div>
    </div>
  )
}

export default RegisterForm
