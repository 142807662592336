const firebaseConfig = {
  apiKey: 'AIzaSyDymX4BFF7CVFI_4gNeb_me53mgznFPBy8',
  authDomain: 'ddfreedrop.firebaseapp.com',
  databaseURL: 'https://ddfreedrop.firebaseio.com',
  projectId: 'ddfreedrop',
  storageBucket: 'ddfreedrop.appspot.com',
  messagingSenderId: '587858768087',
  appId: '1:587858768087:web:8b1da45c1f48713f110092',
  measurementId: 'G-H9ETGSEFPJ',
}

export default firebaseConfig
