import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import firebase from '../firebase'

const CustomerEdit = () => {
  let { uuid } = useParams()
  let history = useHistory()

  const [customer, setCustomer] = useState({})
  const [frmCustomer, setFrmCustomer] = useState({
    name: '',
    email: '',
    tel: '',
  })

  useEffect(() => {
    let isSubscribed = true
    const getCustomer = async () => {
      let dbRef = firebase.database().ref(`userData/${uuid}`)
      // dbRef.once('value').then((snapshot) => {
      //   let obj = snapshot.val()
      //   setCustomers(Object.entries(obj))
      // })

      dbRef.once('value', async (snapshot) => {
        //setCustomer(snapshot.val())
        setFrmCustomer({
          name: snapshot.val().name,
          email: snapshot.val().email,
          tel: snapshot.val().tel,
        })
      })
    }

    if (firebase.apps.length) {
      getCustomer()
    }

    return () => (isSubscribed = false)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    let dbRef = firebase.database().ref(`userData/${uuid}`)
    await dbRef.set(frmCustomer)

    alert('Submit')
    history.push('/admin/customer')
  }

  if (!firebase.apps.length) {
    return <div>Loading</div>
  }

  return (
    <div style={{ paddingTop: '60px' }}>
      <div className="ui container">
        <form className="ui form" onSubmit={handleSubmit}>
          <div className="field">
            <label>Name</label>
            <input
              type="text"
              value={frmCustomer.name}
              onChange={(e) => {
                setFrmCustomer({ ...frmCustomer, name: e.target.value })
              }}
            />
          </div>
          <div className="field">
            <label>Tel</label>
            <input
              type="text"
              value={frmCustomer.tel}
              onChange={(e) => {
                setFrmCustomer({ ...frmCustomer, tel: e.target.value })
              }}
            />
          </div>
          <div className="field">
            <label>E-Mail</label>
            <input
              type="text"
              value={frmCustomer.email}
              onChange={(e) => {
                setFrmCustomer({ ...frmCustomer, email: e.target.value })
              }}
            />
          </div>
          <button className="ui blue button" type="submit">
            Submit
          </button>
          <button
            className="ui red button"
            onClick={() => {
              history.push('/admin/customer')
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  )
}

export default CustomerEdit
